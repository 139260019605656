import _ from "lib/lodash";

import { portalRouteTypes, stickerRouteTypes, localeTypes } from "components/handlers/types";

export const importComponentMiddleware = (store) => (next) => (action) => {
	next(action);

	if (
		action.type !== portalRouteTypes.PORTAL_ROUTE_COMPONENT_LOAD_REQUEST &&
		action.type !== stickerRouteTypes.STICKER_ROUTE_COMPONENT_LOAD_REQUEST
	)
		return;

	const { component, onSuccessActions, onErrorActions } = action;

	const resources = {
		"profiles/default/portals/default": import("profiles/default/portals/default"),
		"profiles/default/stickers/default/roles/master": import(
			"profiles/default/stickers/default/roles/master"
		),
		"profiles/default/stickers/default/roles/slave": import(
			"profiles/default/stickers/default/roles/slave"
		),
		"profiles/default/stickers/demo-event-gold-premium/roles/master": import(
			"profiles/default/stickers/demo-event-gold-premium/roles/master"
		),
		"profiles/default/stickers/demo-event-gold-premium/roles/slave": import(
			"profiles/default/stickers/demo-event-gold-premium/roles/slave"
		),

		"profiles/helvetia-ch/portals/default": import("profiles/helvetia-ch/portals/default"),
		"profiles/helvetia-ch/stickers/pilot-18/roles/master": import(
			"profiles/helvetia-ch/stickers/pilot-18/roles/master"
		),
		"profiles/helvetia-ch/stickers/pilot-18/roles/slave": import(
			"profiles/helvetia-ch/stickers/pilot-18/roles/slave"
		),

		"profiles/aida/portals/default": import("profiles/aida/portals/default"),
		"profiles/aida/stickers/mailing-18/roles/master": import(
			"profiles/aida/stickers/mailing-18/roles/master"
		),
		"profiles/aida/stickers/mailing-18/roles/slave": import(
			"profiles/aida/stickers/mailing-18/roles/slave"
		),

		"profiles/demo-insurance/portals/default": import("profiles/demo-insurance/portals/default"),
		"profiles/demo-insurance/stickers/pilot-18/roles/master": import(
			"profiles/demo-insurance/stickers/pilot-18/roles/master"
		),
		"profiles/demo-insurance/stickers/pilot-18/roles/slave": import(
			"profiles/demo-insurance/stickers/pilot-18/roles/slave"
		),

		"profiles/demo-smartconcierge19/portals/default": import(
			"profiles/demo-smartconcierge19/portals/default"
		),
		"profiles/demo-smartconcierge19/stickers/default/roles/master": import(
			"profiles/demo-smartconcierge19/stickers/default/roles/master"
		),
		"profiles/demo-smartconcierge19/stickers/default/roles/slave": import(
			"profiles/demo-smartconcierge19/stickers/default/roles/slave"
		),

		"profiles/helvetia-at/portals/default": import("profiles/helvetia-at/portals/default"),
		"profiles/helvetia-at/stickers/pilot-19/roles/master": import(
			"profiles/helvetia-at/stickers/pilot-19/roles/master"
		),
		"profiles/helvetia-at/stickers/pilot-19/roles/slave": import(
			"profiles/helvetia-at/stickers/pilot-19/roles/slave"
		),

		"profiles/folder-insurance20/portals/default": import(
			"profiles/folder-insurance20/portals/default"
		),
		"profiles/folder-insurance20/stickers/default/roles/master": import(
			"profiles/folder-insurance20/stickers/default/roles/master"
		),
		"profiles/folder-insurance20/stickers/default/roles/slave-a": import(
			"profiles/folder-insurance20/stickers/default/roles/slave-a"
		),
		"profiles/folder-insurance20/stickers/default/roles/slave-h": import(
			"profiles/folder-insurance20/stickers/default/roles/slave-h"
		),
		"profiles/folder-insurance20/stickers/default/roles/slave-f": import(
			"profiles/folder-insurance20/stickers/default/roles/slave-f"
		),

		"profiles/my-stx/portals/default": import("profiles/my-stx/portals/default"),
		"profiles/my-stx/stickers/default/roles/master": import(
			"profiles/my-stx/stickers/default/roles/master"
		),
		"profiles/my-stx/stickers/default/roles/slave": import(
			"profiles/my-stx/stickers/default/roles/slave"
		),
	};

	resources[component].then(
		(Component) =>
			_.forEach(onSuccessActions, (a) => store.dispatch({ ...a, Component: Component.default })),
		(error) => _.forEach(onErrorActions, (a) => store.dispatch({ ...a, error }))
	);
};

export const importTranslationsMiddleware = (store) => (next) => (action) => {
	next(action);

	if (
		action.type !== portalRouteTypes.PORTAL_ROUTE_TRANSLATIONS_LOAD_REQUEST &&
		action.type !== stickerRouteTypes.STICKER_ROUTE_TRANSLATIONS_LOAD_REQUEST
	)
		return;

	const { profile, onSuccessActions } = action;
	const { locale } = store.getState().localeHandler;

	// const resources = {
	// 	"profiles/default/translations/de": import(/* webpackChunkName: "default-de" */ 'profiles/default/translations/de.json'),
	// 	"profiles/default/translations/en": import(/* webpackChunkName: "default-en" */ 'profiles/default/translations/en.json'),
	// 	"profiles/aida/translations/de": import(/* webpackChunkName: "aida-de" */ 'profiles/aida/translations/de.json'),
	// 	"profiles/aida/translations/en": import(/* webpackChunkName: "aida-en" */ 'profiles/aida/translations/de.json'),
	// 	"profiles/helvetia-ch/translations/de": import(/* webpackChunkName: "helvetia-ch-de" */ 'profiles/helvetia-ch/translations/de.json'),
	// 	"profiles/helvetia-ch/translations/en": import(/* webpackChunkName: "helvetia-ch-en" */ 'profiles/helvetia-ch/translations/en.json'),
	// 	"profiles/helvetia-ch/translations/fr": import(/* webpackChunkName: "helvetia-ch-fr" */ 'profiles/helvetia-ch/translations/fr.json'),
	// 	"profiles/helvetia-ch/translations/it": import(/* webpackChunkName: "helvetia-ch-it" */ 'profiles/helvetia-ch/translations/it.json'),
	// };

	// let promise = null;
	// switch (profile) {
	// 	case "default": promise = import(/* webpackChunkName: "[request]" */ `profiles/default/translations/${locale}.json`); break;
	//  	case "aida": promise = import(/* webpackChunkName: "[request]" */ `profiles/aida/translations/${locale}.json`); break;
	//  	case "helvetia-ch": promise = import(/* webpackChunkName: "[request]" */ `profiles/helvetia-ch/translations/${locale}.json`); break;
	//  	default: break;
	//  }

	import(`profiles/${profile}/translations/${locale}.json`)
		//promise
		//resources[`profiles/${profile}/translations/${locale}`]
		.then((translationComponent) =>
			store.dispatch({ type: localeTypes.LOCALE_ADD_MESSAGES, messages: translationComponent })
		)
		.then(() => _.forEach(onSuccessActions, store.dispatch))
		.catch(() => _.forEach(onSuccessActions, store.dispatch)); // no error actions available
};

import React from 'react';
import styled from 'styled-components/macro';
import {FormRadioGroup} from 'components/forms'

const FormRadioGroupWrapper = ({className, styled, children, ...otherProps}) =>
	<FormRadioGroup className={className} {...otherProps}>{children}</FormRadioGroup>;

export default styled(FormRadioGroupWrapper)`
	margin: 0 0 16px 0;
	
	label {
		color: ${({styled, theme}) => theme.textColor};
    font-size: 16px;
    font-weight: 500;

    div {
      color: #009c33 !important;
    }
	}
	
  #errorText {
    font-size: 16px;
  	color: ${({theme}) => theme.alertTextColor};
  }
`;